.mainNavbarContainer {
  background: #c3ccea;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  overflow: auto;
}

.navigationContainer {
  margin-left: 13px;
  margin-right: 13px;
}

.navbarButton {
  padding: 10px;
  margin-top: 13px;
  margin-bottom: 13px;
  border-radius: 6px;
}

.navbarButtonSelected {
  background-color: #414288;
}

.profileContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 13px;
  cursor: pointer;
}

.avatar {
  width: 75px;
  height: 75px;
  border-radius: 37.5px;
  background-color: #16b4a4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatarText {
  color: white;
  font-family: "Avenir";
  font-weight: 900;
  font-size: 30px;
  margin: 0;
}
