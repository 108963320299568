.overlayScreenCover {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(128, 128, 128, 0.5);
  z-index: 2;
}

.overlayContainer {
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 20px;
  z-index: 3;
}
