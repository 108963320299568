.page {
  background-color: rgb(241, 242, 248);
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.contentScrollContainer {
  height: 100%;
  display: flex;
  overflow: auto;
}

.contentPadding {
  padding: 52px;
  display: flex;
}
