#loginPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

#loginBody {
  width: 30rem;
}

.loginLogo {
  width: 300px;
}

.loginFormGroup {
  margin-bottom: 20px;
}
