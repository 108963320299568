.listScrollContainer {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

table {
  border-collapse: collapse;
  /*height: 100%;*/
}

th {
  padding: 0px;
  vertical-align: top;
  position: sticky;
  top: 0;
}

tr {
  border: solid;
  border-color: #d2d2d2;
  border-width: 1px 0;
}

tr.tableHeader {
  border: none;
}

tr:first-child {
  border-top: none;
  border-bottom: none;
}

tr:last-child {
  border-bottom: none;
}

.columnHeader {
  height: 100%;
  /*border-bottom: solid #9f9fa0 1px;*/
  box-shadow: inset 0px -1px #9f9fa0;
  background-color: white;
}

.columnHeaderContainer {
  /*border-bottom: solid #9f9fa0 1px;*/
  /* background-color: red; */
}

.columnHeaderPadding {
  padding-right: 100px;
}

.colHeaderFormContainer {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}

.colHeaderFormContainerPadded {
  display: flex;
  padding: 10px 100px 10px 10px;
  white-space: nowrap;
}

.colHeaderFormContainerCentered {
  display: flex;
  white-space: nowrap;
}

.dropdownColHeaderFormContainer {
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.colHeaderForm {
  white-space: nowrap;

  font-family: "Avenir", "sans-serif";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  letter-spacing: 0.526191px;
  text-transform: uppercase;

  color: #717678;
}

.colHeaderCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.colHeaderTextWithSort {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.textInputColHeaderText {
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.entryContainer {
  padding-top: 40px;
  padding-bottom: 30px;
  padding-left: 20px;
}

.entryText {
  max-width: 220px;

  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;

  letter-spacing: 0.526191px;

  color: #31393c;

  /*flex-wrap: nowrap;*/
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.entryTextCenter {
  text-align: center;
}

.entryTextUnderline {
  text-decoration-line: underline;
}

.entryTextUnderline:hover {
  cursor: pointer;
  color: #16b4a4;
}

.locationCell {
  max-width: 350px;
}

.nameCell {
  cursor: pointer;
}

.passionCell {
  overflow: scroll;
  text-overflow: unset;
  font-size: 12px;
  max-width: 300px;
  padding-bottom: 5px;
}

.tagContainer {
  display: flex;
  max-width: 300px;
  overflow-y: auto;
}

.tagHolder {
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
}

.tagBox {
  margin: 0px 2.5px;
  background-color: #16b4a4;
  border-radius: 6px;
}

.tagText {
  font-family: "Avenir", "sans-serif";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.526191px;
  margin: 0px 10px;

  color: #ffffff;
  white-space: nowrap;
}

.inputText {
  font-family: Avenir, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.526191px;
  color: #31393c;
}

table input {
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  padding-left: 20px;
  background-image: url("../../assets/icons/MagnifyingGlass.png");
  background-repeat: no-repeat;
  background-position: 2px 50%;
  margin-bottom: 20px;
}

.dropdownColHeaderText {
  margin: 0px 0px 10px 0px;
  text-align: center;
}

.dropdown {
  display: flex;
  align-self: center;
  margin-right: 75px;
  margin-left: 75px;
}

select {
  border: 1px solid #d2d2d2;
  border-radius: 4px;
}

.dropdownDefaultOptionText {
  font-family: Avenir, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.526191px;

  color: #717678;
}

.sortRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}

.sortOption {
  display: flex;
  height: 29px;
  width: 29px;
  margin-left: 5px;
  cursor: pointer;
  color: #717678;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.selectedSortOption {
  background-color: #c3ccea;
}

.isBlockedImage {
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

.bounceContainer {
  margin: 50px;
  min-width: 100px;
}
