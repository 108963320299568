.App {
  min-height: 100%;
  height: 100%;
}

.App-link {
  color: #61dafb;
}

.navbar {
  min-height: 100%;
}

.navbarPageWrap {
  min-height: 100%;
  height: 100%;
  overflow: auto;
}

.page {
  min-height: 100%;
  height: 100%;
  overflow: auto;
}
