.iconContainer {
  background: #c3ccea;
  border-radius: 10px;
  padding: 4px;
  display: flex;
  height: 38px;
  width: 38px;
}

.infoBoxContainer {
  display: flex;
  flex-direction: column;
}

.infoBoxHeaderRow {
  display: flex;
  flex-direction: row;

  margin-bottom: 25px;
}

.infoBoxHeaderTitle {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  letter-spacing: 0.526191px;

  color: #31393c;

  margin: 0px 0px 0px 30px;

  white-space: nowrap;
}

.infoColumnContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.infoColumnHeader {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: 0.526191px;
  text-transform: uppercase;

  color: #717678;

  margin: 0px;
  white-space: nowrap;
}

.infoColumnBody {
  font-family: Avenir, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 90px;
  line-height: 123px;

  text-align: center;
  letter-spacing: 0.526191px;

  color: #414288;
  margin-bottom: 0px;
}

.infoColumnFooter {
  font-family: Avenir, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;

  letter-spacing: 0.526191px;

  color: #16b4a4;

  white-space: nowrap;

  margin-bottom: 0;
}

.dashboardTopColumns {
  display: flex;
  flex-direction: row;
}

.secondBoxInfoColumnRow {
  display: flex;
  flex-direction: row;
}

.secondBoxInfoColumnDivider {
  width: 75px;
}

.dashboardBottomColumns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: stretch;
}

.bottomColumnInfoBoxContainer {
  flex-grow: 1;
}
