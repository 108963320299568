.headerContainer {
  display: flex;
  align-items: flex-end;
  flex-shrink: 1;
  background-color: white;
  padding-left: 53px;
  padding-bottom: 20px;
  height: 128px;
}

.headerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  margin-right: 50px;
}

.headerText {
  color: #333333;
  font-family: "Lato", sans-serif;
  font-size: 50px;
  font-weight: 900;
  margin: 0;
}

.headerImgContainer {
  display: flex;
  height: 50px;
}

.headerImg {
  display: "flex";
  flex-shrink: 1;
}
